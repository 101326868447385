import React, { useEffect, useRef, useState } from 'react';
import AuthLayout from '../components/layout/AuthLayout';
import { useHistory, useLocation } from 'react-router-dom/cjs/react-router-dom.min';
import { LSK_MCA_STATUS, getUserTransactionToken, setItem, setUserAuth } from '../../utils/LocalStorageUtils';
import { apiEnableResendOtp, apiVerifyEmail, verifyOtp } from '../apis/apis';
import { useSnackbar } from '../../context/SnackbarContext';
import { OTP_SENT_TO_EMAIL, OTP_SENT_TO_MOBILE } from '../../utils/constants/constants';


function OtpVerification() {

    const history = useHistory()
    const location = useLocation()
    const { showSnackbar } = useSnackbar()

    const { otpSentTo } = location.state || {};
    const [ valOtpSentTo, setValOtpSentTo ] = useState(otpSentTo);

    const [otp, setOtp] = useState(new Array(4).fill(""))
    const [isLoading, setIsLoading] = useState(false)

    const transactionToken = getUserTransactionToken()

    // TODO: use constants for local storage keys - Wilson
    const mobileNumber = localStorage.getItem("mobileNumber")
    const firstName = localStorage.getItem("firstName");
    const lastName = localStorage.getItem("lastName");
    const email = localStorage.getItem("email");

    const firstInputRef = useRef(null)

    const onSubmit = async (e) => {
        e.preventDefault()

        const login = !!firstName
        const val = {
            transactionToken,
            otp: otp.join(""),
            firstName,
            lastName,
            email,
            login,
        };

        if (otp.some((value) => value === "")) {
            showSnackbar("Please enter a valid OTP")
            setIsLoading(false)
        } else {
            setIsLoading(true);

            try {
                const data = valOtpSentTo === OTP_SENT_TO_EMAIL ? await apiVerifyEmail(val) : await verifyOtp(val);


                if(data.requireMobileVerification){
                    showSnackbar('Email verified.');
                    
                    setValOtpSentTo(data.otpSentTo);
                    setOtp(new Array(4).fill(""));
                } else {
                    setUserAuth(true);
                    saveUserDetails(data.user);
                }
                setIsLoading(false)
            } catch (error) {
                setUserAuth(false)
                showSnackbar(error)
                setIsLoading(false)
            }
        }
    }

    useEffect(() => {
        if (firstInputRef.current) {
            firstInputRef.current.focus()
        }
    }, [])

    //TODO : move below block to interceptor
    const saveUserDetails = (data) => {
        // localStorage.setItem("firstName", data.firstName);
        // localStorage.setItem("lastName", data.lastName);
        localStorage.setItem("fullName", data.fullName);
        localStorage.setItem("email", data.email);
        localStorage.setItem("mobileNumber", data.mobile);

        setItem(LSK_MCA_STATUS, data.mcaStatus);

        // TODO : navigating to home directly for first version
        // history.replace("/user/home")
        if (!data.ckycStatus) {
            history.push("/user/successfull");
        } else if (
            (data.dobDoi === null || data.dobDoi === "") ||
            (data.address === null || data.address === "") ||
            (data.investorType === null || data.investorType === "")) {
            history.push("/user/communication-details");
        } else if (
            ((data.bankName === '' || data.bankName === null) && (data.bankNameIndex === 0 || data.bankNameIndex === null)) ||
            (data.bankAddress === '' || data.bankAddress === null) ||
            (data.accountNo === '' || data.accountNo === null) ||
            (data.ifsc === '' || data.ifsc === null)) {
            history.push("/user/bank-details")
        } else if (data.mcaStatus === 0){
            history.push("/user/successfull");
        }
        else {
            history.push("/user/home");
        }
    }

    const handleChange = (element, index) => {
        const value = element.value;

        if (!isNaN(value)) {
            const updatedOtp = [...otp];
            updatedOtp[index] = value;
            setOtp(updatedOtp);

            // Focus next input
            if (element.nextSibling) {
                element.nextSibling.focus();
            }
        }
    }

    const navigateBack = () => {
        history.goBack();
    }

    const onResendOtp = () => {
        const reqBody = {
            "transactionToken": getUserTransactionToken(),
        }
        apiEnableResendOtp(reqBody)
            .then((res) => {
                showSnackbar("OTP sent successsfully") 
            })
            .catch((e) => {
                showSnackbar(e.toString())
            })
    }
    return (
        <AuthLayout title={
            valOtpSentTo === OTP_SENT_TO_EMAIL
            ? 'Verify Email'
            : valOtpSentTo === OTP_SENT_TO_MOBILE
            ? 'Verify Mobile Number'
            : 'Verify OTP'
        }>
            <p>
                {
                    valOtpSentTo === OTP_SENT_TO_EMAIL
                    ? 'Enter the 4-digit OTP sent to your registered email address to proceed.'
                    : valOtpSentTo === OTP_SENT_TO_MOBILE
                    ? 'Enter the 4-digit OTP sent to your registered mobile number to proceed.'
                    : 'Enter the 4-digit OTP sent to your registered mobile number and email address to proceed.'
                }
            </p>
            <form onSubmit={onSubmit}>
                <div className="d-flex otp-wrapper">
                    {otp.map((item, index) => (
                        <input
                            className="form-control py-3 me-2 text-center"
                            type="text"
                            name="otp"
                            maxLength="1"
                            key={index}
                            value={item}
                            onChange={(e) => handleChange(e.target, index)}
                            onFocus={(e) => e.target.select()}
                            ref={index === 0 ? firstInputRef : null}
                        />
                    ))}
                </div>
                {/* <p className='mt-4'>Didn't receive OTP? <a className='text-decoration-none text-primary' href="/register">Resend OTP</a></p> */}
                <div className='d-flex mt-3'>
                    <button type="submit" disabled={isLoading} className="btn btn-primary px-3 py-2">Verify</button>
                    <button type="button" className="btn btn-light px-3 ms-3 py-2" onClick={navigateBack} >Edit</button>
                </div>
                <div className="d-flex gap-2  mt-3">
                    <span className="fs-med-medium">Didn't receive OTP?</span>
                    <span className="fs-med-medium text-primary" style={{cursor:'pointer'}} onClick={onResendOtp}>
                        Resend
                    </span>
                </div>
            </form>
            <div className="d-flex"></div>
        </AuthLayout>
    );
}

export default OtpVerification;