import React, { useEffect, useState } from 'react';
import AuthLayout from '../../components/layout/AuthLayout';
import { useHistory, useLocation } from 'react-router-dom/cjs/react-router-dom.min';
import { apiSaveBasicDetail, getDataDictionaryValues, getUserDetails } from '../../apis/apis';
import { useSnackbar } from '../../../context/SnackbarContext';
import { DD_TYPE_BANK_TYPE, DD_TYPE_INVESTOR_TYPE } from '../../../utils/constants/constants';

const CommunicationDetails = () => {
    let history = useHistory();
    const { showSnackbar } = useSnackbar()
    const [data, setData] = useState({
        fullName: "",
        dobDoi: "",
        investorType: null,
        address: ""
    });
    const [investorTypeList, setInvestorTypeList] = useState([]);

    const [isLoading, setIsLoading] = useState(false);

    const handle = (e) => {
        const { id, value } = e.target;
        setData((prevData) => ({
            ...prevData,
            [id]: value,
        }));
    };

    const submit = (e) => {
        e.preventDefault();

        if (!data?.dobDoi || data.dobDoi.trim() === "") {
            showSnackbar("Please select DOB/DOI");
            return;
        }

        if (!data.address?.trim()) {
            showSnackbar("Please enter an address");
            return;
        }

        if (!data.investorType) {
            showSnackbar("Please select investor type");
            return;
        }

        setIsLoading(true);

        apiSaveBasicDetail(data)
            .then(() => {
                history.push("/user/bank-details");
            })
            .catch((err) => {
                showSnackbar(err);
            })
            .finally(() => setIsLoading(false));
    };

    const fetchUserData = async () => {
        const userDetails = await getUserDetails();

        if (userDetails.status) {
            const { address, fullName, dobDoi, investorType } = userDetails.data;

            setData({
                fullName: fullName,
                dobDoi: dobDoi?.split(' ')[0],
                investorType: investorType,
                address: address
            });
        }
    };

    const getDataDictionary = () => {
        getDataDictionaryValues()
            .then((data) => {
                const invTypes = data.filter((item) =>
                    item.ddType === DD_TYPE_INVESTOR_TYPE
                )
                setInvestorTypeList(invTypes);
            })
            .catch((e) => {
                console.log(e);
            })
    }

    useEffect(() => {
        fetchUserData();
        getDataDictionary();
    }, []);

    return (
        <AuthLayout title="Other Details">
            <form onSubmit={submit}>
                <div className="form-floating mb-3">
                    <input
                        className="form-control"
                        placeholder="DOB"
                        name="dobDoi"
                        type="date"
                        value={data.dobDoi || ""}
                        id="dobDoi"
                        onChange={handle}
                    />
                    <label htmlFor="dobDoi">DOB</label>
                </div>
                <div className="form-floating mb-3">
                    <input
                        className="form-control"
                        placeholder="Address"
                        name="address"
                        value={data.address}
                        id="address"
                        onChange={handle}
                    />
                    <label htmlFor="address">Address</label>
                </div>
                <div className="form-floating mb-3">
                    <select
                        class="form-select"
                        id="investorType"
                        name="investorType"
                        onChange={handle}
                        value={data.investorType}
                        aria-label="Default select example"
                    >
                        <option value={null}></option>
                        {investorTypeList?.map((item) => (
                            <option value={item.ddIndex}>{item.ddValue}</option>
                        ))}
                    </select>
                    <label htmlFor="investorType">Investor Type</label>
                </div>

                <div className='d-flex mt-3'>
                    <button type="submit" disabled={isLoading} className="btn btn-primary px-3 py-2">Save & Continue</button>
                </div>
            </form>
        </AuthLayout>
    );
}

export default CommunicationDetails;