import React from "react";
import Tooltip from "@mui/material/Tooltip";

const ProgressBar = ({
  existingCommitmentWidth,
  sprintCommitmentWidth,
  fundReceivedWidth,
  committedPercentage,
  fundedPercentage
}) => {
  const minPercentage = 0.0001; // Minimum percentage to avoid zero-width sections

  // Calculate section widths in percentages
  const calculateSectionPercentage = (percentage) =>
    percentage <= 0 ? 0 : percentage >= minPercentage ? percentage : minPercentage;

  const existingCommitmentPercentage = calculateSectionPercentage(existingCommitmentWidth);
  const fundReceivedPercentage = calculateSectionPercentage(fundReceivedWidth);
  const sprintCommitmentPercentage = calculateSectionPercentage(sprintCommitmentWidth);
  const remainingPercentage =
    100 -
    (existingCommitmentPercentage + sprintCommitmentPercentage + fundReceivedPercentage);

  return (
    <div style={{ display: "flex", flexDirection: "column", alignItems: "center", margin: "20px" }}>
      <div className="d-flex gap-2 justify-content-start" style={{ width: "100%" }}>
        {/* Progress Bar */}
        <div
          style={{
            width: "100%",
            height: "30px",
            backgroundColor: "#d3f9d8", // Green background
            borderRadius: "100px",
            position: "relative",
            display: "flex",
            overflow: "hidden",
          }}
        >
          {/* Existing Commitment Section */}
          {existingCommitmentPercentage > 0 && (
            <Tooltip
              title="External Commitment"
              arrow
              placement="top"
              componentsProps={{
                tooltip: {
                  sx: {
                    bgcolor: "#E6F1F8", // Light blue tooltip background
                    color: "#5A5A5A",
                    fontSize: "0.875rem",
                    border: "1px solid #ADD8E6",
                  },
                },
              }}
            >
              <div
                style={{
                  width: `${existingCommitmentPercentage}%`,
                  backgroundColor: "#ADD8E6", // Light blue
                  height: "100%",
                }}
              />
            </Tooltip>
          )}

          {/* Fund Received Section */}
          {fundReceivedPercentage > 0 && (
            <Tooltip
              title="Fund Received"
              arrow
              placement="top"
              componentsProps={{
                tooltip: {
                  sx: {
                    bgcolor: "#D1C4E9", // Light purple tooltip background
                    color: "#5A5A5A",
                    fontSize: "0.875rem",
                    border: "1px solid #B39DDB",
                  },
                },
              }}
            >
              <div
                style={{
                  width: `${fundReceivedPercentage}%`,
                  backgroundColor: "#B39DDB", // Light purple
                  height: "100%",
                }}
              />
            </Tooltip>
          )}

          {/* Sprint Commitment Section */}
          {sprintCommitmentPercentage > 0 && (
            <Tooltip
              title="Sprint Commitment"
              arrow
              placement="top"
              componentsProps={{
                tooltip: {
                  sx: {
                    bgcolor: "#E8E2F4", // Light purple tooltip background
                    color: "#5A5A5A",
                    fontSize: "0.875rem",
                    border: "1px solid #CBC3E3",
                  },
                },
              }}
            >
              <div
                style={{
                  width: `${sprintCommitmentPercentage}%`,
                  backgroundColor: "#CBC3E3", // Light purple
                  height: "100%",
                }}
              />
            </Tooltip>
          )}


          {/* Remaining Section */}
          {remainingPercentage > 0 && (
            <Tooltip
              title="Balance"
              arrow
              placement="top"
              componentsProps={{
                tooltip: {
                  sx: {
                    bgcolor: "#D3D3D3", // Light green tooltip background
                    color: "#5A5A5A",
                    fontSize: "0.875rem",
                    border: "1px solid #B7E2B7",
                  },
                },
              }}
            >
              <div
                style={{
                  width: `${remainingPercentage}%`,
                  backgroundColor: "#D3D3D3", // Light green
                  height: "100%",
                }}
              />
            </Tooltip>
          )}
        </div>

        {/* Label for Committed Percentage */}
        <div style={{ fontSize: "16px", fontWeight: "bold", marginLeft: "10px" }}>
          {`${committedPercentage}% Committed`}
        </div>

         {/* Label for Funded Percentage */}
         <div style={{ fontSize: "16px", fontWeight: "bold", marginLeft: "10px" }}>
          {`${fundedPercentage}% Funded`}
        </div>
      </div>
    </div>
  );
};

export default ProgressBar;

